import {
    ArrayField, BooleanField, ChipField, Datagrid, DateField, List, NumberField,
    ReferenceField, SingleFieldList, TextField, TextInput
} from "react-admin";


const orderFilters = [
    <TextInput label="TotalCost" source="totalCost" alwaysOn />,
    <TextInput label="Status" source="status" />,
];

const OrderBulkActionButtons = ({ selectedIds }) => {

};

export const OrderList = props => (
    <List {...props} filters={orderFilters} bulkActionButtons={<OrderBulkActionButtons />} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="store.storeName" label={'Store'} />
            <TextField source="user.userName" label={'User Name'} />
            <TextField source="orderStatus" />
            <TextField source="paymentStatus" />
            <TextField source="returnStatus" />
            <TextField source="totalCost" />
            <TextField source="currency" />
            <ReferenceField source="paymentId" reference="payments"><TextField source="id" /></ReferenceField>
            <TextField source="user.postNumber" label={'Post Number'} />
            <ReferenceField source="userId" reference="users"><TextField source="id" /></ReferenceField>
            <ArrayField source="items"><SingleFieldList><ChipField source="discount" /></SingleFieldList></ArrayField>
            <TextField source="userDescription" />
            <TextField source="shipping.address.city" label={'City'} />
            <BooleanField source="deliverToAddress" />
            <NumberField source="shippingPrice" />
            <DateField source="createAt" />
        </Datagrid>
    </List>
);