import { Datagrid, DateField, List, NumberField, ReferenceField, TextField } from "react-admin";

const PostBulkActionButtons = () => (
    <>
    </>
);

export const PaymentList = props => (
    <List {...props} bulkActionButtons={<PostBulkActionButtons />} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="authorizationIdentifier" />
            <DateField source="paidAt" />
            <ReferenceField source="orderId" reference="orders"><TextField source="id" /></ReferenceField>
            <TextField source="currency" />
            <TextField source="paymentIdentifier" />
            <NumberField source="amount" />
        </Datagrid>
    </List>
);