import * as React from 'react';
import { AppBar, defaultTheme, ToggleThemeButton } from 'react-admin';
import { createTheme, Typography } from '@mui/material';
import Logo from '../assets/images/logo192.png';

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};

const darkTheme = createTheme({
    palette: { mode: 'dark' },
});

const MyAppBar = props => {
    return (
        <AppBar {...props} color="inherit">
            <Typography
                variant="h6"
                color="inherit"
                sx={styles.title}
                id="react-admin-title"
            />
            <img src={Logo} alt='logo' height={20.475} style={{ padding: 5 }} />
            <p> Neowshop </p>
            <span style={styles.spacer} />
            <ToggleThemeButton
                lightTheme={defaultTheme}
                darkTheme={darkTheme}
            />
        </AppBar>
    );
};

export default MyAppBar;