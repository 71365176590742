
import * as React from "react";
import {
    BooleanField,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    ImageField,
    List,
    NumberField,
    NumberInput,
    ReferenceField,
    SaveButton,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    Toolbar,
} from "react-admin";
import { useUpdateMany } from 'react-admin';

const productFilters = [
    <TextInput label="Title" source="title" alwaysOn />,
    <TextInput label="StoreId" source="storeId" alwaysOn />,
    <TextInput label="Status" source="status" alwaysOn />,
];

const BulkResetViewsButton = ({ selectedIds }) => {
    const [updateMany, { isLoading, error }] = useUpdateMany();
    const handleEnableClick = () => {
        updateMany(
            'products',
            { ids: selectedIds, data: { status: 2 } }
        );
    }
    const handleDisableClick = () => {
        updateMany(
            'products',
            { ids: selectedIds, data: { status: 1 } }
        );
    }
    if (error) { return <p>ERROR</p>; }
    return <>
        <button disabled={isLoading} onClick={handleEnableClick}>Enable</button>
        <button disabled={isLoading} onClick={handleDisableClick}>Disable</button>
    </>
};

export const ProductList = props => (
    <List {...props} filters={productFilters} bulkActionButtons={<BulkResetViewsButton />} >
        <Datagrid >
            <EditButton label="" />
            <ShowButton label="" />
            <TextField source="id" />
            <NumberField source="storeStatus" textAlign="center" />
            <NumberField source="status" textAlign="center" />
            <TextField source="oldPrice" />
            <NumberField source="favorited" textAlign="center" />
            <DateField source="updateAt" />
            <TextField source="title" />
            <TextField source="brands" />
            <TextField source="sizes" />
            <TextField source="detailType" />
            <NumberField source="discount" textAlign="center" />
            <TextField source="categorySeason" />
            <NumberField source="visitCount" textAlign="center" />
            <TextField source="categoryType" />
            {/* <ArrayField source="images"><SingleFieldList><ChipField source="src" /></SingleFieldList></ArrayField> */}
            <BooleanField source="deliverToAddress" label='Deliver To Add' textAlign="center" />
            <DateField source="createAt" />
            <NumberField source="price" />
            <TextField source="genderType" />
            <ReferenceField source="storeId" reference="stores"><TextField source="id" /></ReferenceField>
            <TextField source="type" />
            <TextField source="materials" />
            {/* <TextField source="description" /> */}
            <BooleanField source="pickUpFromStore" minWidth={70} />
        </Datagrid>
    </List>
);

const UserEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const ProductEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<UserEditToolbar />}>
            <TextInput disabled source="id" />
            <TextInput disabled source="title" />
            <NumberInput max={2} min={1} source="status" />
        </SimpleForm>
    </Edit>
);


export const ProductShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <NumberField source="status" />
            <TextField source="title" />
            <ImageField source="images" src="src" title="" />
            <ImageField source="colors" src="src" title="" />
        </SimpleShowLayout>
    </Show>
);