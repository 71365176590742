import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
    Datagrid,
    List,
    Filter,
    TextField,
    TextInput,
    DateField,
} from "react-admin";


const RegisteredShopsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search email" source="email" alwaysOn />
    </Filter>
);

const PostBulkActionButtons = () => (
    <>
    </>
);

export const RegisteredShopsList = (props) => (
    <List {...props} filters={<RegisteredShopsFilter />} bulkActionButtons={<PostBulkActionButtons />} >
        <Datagrid>

            <TextField source="id" />
            <TextField source="fullName" />
            <TextField source="phoneNumber" />
            <TextField source="email" />
            <DateField source="createdAt" showTime />
            {/* <EditButton label="" />
            <DeleteButton label="" redirect={false} /> */}
        </Datagrid>
    </List>
);